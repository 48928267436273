<template>
    <section>
        <div class="page-header">
            <h3 class="page-title"> Compagnie </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">e-Commerce</a></li>
                <li class="breadcrumb-item active" aria-current="page">Commande</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-success btn-sm" @click="addCompany()">Ajouter</button>
                            <input type="text" placeholder="Recherche">
                        </div>
                        
                        <table  class="table table-sm mt-5">
                            <thead>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <th scope="col">SIREN</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <div v-if="isload" class="jumping-dots-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <tbody  v-else>
                                <tr class="pointer" v-for="(company,company_id) in companies" :key="company_id" >
                                    <td @click="companyDetails(company.ulid)">{{company.name}}</td>
                                    <td @click="companyDetails(company.ulid)">{{company.type}}</td>
                                    <td class="d-flex justify-content-end">
                                        <button type="button" class="btn btn-social-icon btn-outline-info" @click="editCompany(company.ulid)"><i class="fa fa-pencil"></i></button>
                                        <button type="button" class="btn btn-social-icon btn-outline-danger btn-sm" @click="deleteCompany(company.ulid)"><i class="mdi mdi-delete"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"name",
    data(){
        return{
            companies:[],
            isload:false
        }
    },
    methods:{
        getCompany(){
            this.isload = true
            axios.get('companies')
            .then(resGetCompany => {
                this.companies = resGetCompany.data.result
                this.isload = false
            })
            .catch(errGetCompany => {
            console.log(errGetCompany)
            this.isload = true
            })
        },
        companyDetails(ulid){
            this.$router.push({
                name:"companyDetails",
                params:{
                    ulid:ulid
                }
            })
        },
        addCompany(){
            this.$router.push({
                name:"addCompany"
            })
        },
        editCompany(ulid){
            this.$router.push({
                name:"editCompany",
                params:{
                    ulid:ulid
                }
            })
        },
        deleteCompany(ulid){
            this.isload = true
            axios.delete(`companies/${ulid}`,
            {
                headers:{
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then(resDeleteCompany =>{
                console.log(resDeleteCompany)
                this.getCompany()
            })
            .catch(errDeleteCompany=>{
                console.log(errDeleteCompany)
                
                this.isload = false
                })
        }
    },
    mounted(){
        this.getCompany()
    }
}
</script>